import * as React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import {TikTokAdsContext} from "../../app-context"
import {useContext, useEffect} from "react"
import {APP_LIST, TIKTOK_ADS_AD_LIST} from "../../constants"
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import {getAccounts} from "../../api/tiktok"


export default function AppForm() {
    const {state, setState} = useContext(TikTokAdsContext)
    useEffect(() => {
        getAccounts(state.selectedApp).then(response => {
            setState(prevState => {
                return {
                    ...prevState, ...{
                        adAccountsList: response.data
                    }
                }
            })
        })  // eslint-disable-next-line
    }, [state.selectedApp])

    const adAccountList = state.adAccountsList.map(item => {
        return (
            <MenuItem key={item.account_id} value={item.account_id}>{item.name}</MenuItem>
        )
    })

    const handleSelectAppChange = (event) => {
        setState(prevState => {
            return {...prevState, ...{selectedApp: event.target.value}}
        })
    }

    const appList = APP_LIST.map(item => {
        return (
            <MenuItem key={item.code} value={item.code}>{item.name}</MenuItem>
        )
    })

    const adTypeList = TIKTOK_ADS_AD_LIST.map(item => {
        return (
            <MenuItem key={item.code} value={item.code}>{item.name}</MenuItem>
        )
    })

    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Choose App and Ad Type
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <FormControl sx={{m: 1, width: 500}}>
                        <InputLabel variant="outlined" id="application-label-id">Application</InputLabel>
                        <Select
                            labelId="application-label-id"
                            id="app-id"
                            value={state.selectedApp}
                            label="Application"
                            onChange={handleSelectAppChange}
                            fullWidth
                        >
                            {appList}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormControl sx={{m: 1, width: 500}}>
                        <InputLabel variant="outlined" id="ad-type-label-id">Ad Type</InputLabel>
                        <Select
                            labelId="ad-type-label-id"
                            value={state.selectedAdType}
                            label="Ad Type"
                            onChange={(event) => {
                                setState(prevState => {
                                    return {...prevState, ...{selectedAdType: event.target.value}}
                                })
                            }}
                            fullWidth
                        >
                            {adTypeList}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl sx={{m: 1, width: 500}}>
                        <InputLabel variant="outlined" id="ad-accounts-label-id">Ad Account</InputLabel>
                        <Select
                            labelId="ad-accounts-label-id"
                            value={state.selectedAdAccount}
                            label="Ad Account"
                            onChange={
                                (event) => {
                                    setState(prevState => {
                                        return {...prevState, ...{selectedAdAccount: event.target.value}}
                                    })
                                }

                            }
                            fullWidth
                        >
                            {adAccountList}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
