import * as React from 'react'
import {useContext, useEffect, useState} from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import ClearTwoToneIcon from '@mui/icons-material/ClearTwoTone'
import {DataGrid} from '@mui/x-data-grid'
import {AppContext} from "../app-context"
import {getCreatives, getCreativesByCreativeId} from '../api'
import InputBase from '@mui/material/InputBase'
import Paper from '@mui/material/Paper'


export default function CreativeForm() {
    const {state, setState} = useContext(AppContext)
    const [filterKeyValue, setFilterKeyValue] = useState('')

    useEffect(() => {
        setState(prevState => {
            return {...prevState, ...{creativeList: [], isLoading: true, selectedCreatives: []}}
        })
        getCreatives(state.selectedApp, state.selectedAdType).then(response => {
            console.log('Getting creatives')
            setState(prevState => {
                const creatives = response.data
                return {...prevState, ...{creativeList: creatives, isLoading: false}}
            })
        })  // eslint-disable-next-line
    }, [state.selectedApp])

    const columns = [
        {field: 'creative_id', headerName: 'Creative ID', width: 100},
        {field: 'name', headerName: 'Name', width: 450},
        {field: 'app', headerName: 'App', width: 100},
        {field: 'creative_type', headerName: 'Type', width: 100},
        {field: 'created', headerName: 'Created', width: 100},
    ];

    const handleSelectionMode = (rows, details) => {
        const selectedRows = state.creativeList.filter(item => rows.includes(item.id))
        const unselectedRows = state.creativeList.filter(item => !rows.includes(item.id))
        setState(prevState => {
            return {
                ...prevState, ...{
                    selectedCreatives: selectedRows,
                    creativeList: selectedRows.concat(unselectedRows)
                }
            }
        })
    }
    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Choose Creative
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <Paper sx={{p: '2px 4px', display: 'flex', alignItems: 'center', width: 300, mb: 2}}
                           component="form">
                        <InputBase
                            id="filterKey"
                            style={{width: 200}}
                            sx={{ml: 1, flex: 1}}
                            placeholder="Filter by name"
                            value={filterKeyValue}
                            onChange={(e) => {
                                getCreativesByCreativeId(state.selectedApp, e.target.value, state.selectedAdType).then(response => {
                                    console.log('Getting creatives by filter')
                                    setState(prevState => {
                                        const creatives = response.data
                                        const creativeIds = creatives.map(item => item.id)
                                        const oldCreatives = prevState.creativeList.filter(
                                            item => !creativeIds.includes(item.id)
                                        )
                                        const newCreatives = creatives.filter(
                                            item => creativeIds.includes(item.id)
                                        )
                                        const newList = newCreatives.concat(oldCreatives)
                                        return {...prevState, ...{creativeList: newList, isLoading: false}}
                                    })
                                })
                                setFilterKeyValue(e.target.value)
                            }}
                            label="Filter by name" variant="outlined"
                            inputProps={{'aria-label': 'filter creatives by name'}}
                        >
                        </InputBase>
                        <IconButton
                            onClick={(e) => {
                                setFilterKeyValue('')
                            }}
                            sx={{p: '10px'}}
                            aria-label="search"
                        >
                            <ClearTwoToneIcon/>
                        </IconButton>
                    </Paper>
                    <div style={{height: 500, width: '100%'}}>
                        <DataGrid
                            rows={state.creativeList}
                            columns={columns}
                            checkboxSelection
                            loading={state.isLoading}
                            onSelectionModelChange={handleSelectionMode}
                            filterModel={{
                                items: [{columnField: 'name', operatorValue: 'contains', value: filterKeyValue}],
                            }}
                        />
                    </div>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
