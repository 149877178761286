import * as React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import {TikTokAdsContext} from "../../app-context"
import {useContext, useEffect, useState} from "react"
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import {getFlows, getTexts} from "../../api/tiktok"
import InputAdornment from '@mui/material/InputAdornment'
import OutlinedInput from '@mui/material/OutlinedInput'
import {createTikTokAd} from "../../api/tiktok"
import {checkJobStatus} from "../../api"
import Button from "@mui/material/Button"
import Backdrop from "@mui/material/Backdrop"
import CircularProgress from "@mui/material/CircularProgress"
import TableContainer from "@mui/material/TableContainer"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import TableBody from "@mui/material/TableBody"
import TextField from "@mui/material/TextField"


export default function AdForm() {
    const initialLocalState = {
        loading: false,
        isComplete: false,
        response: [],
        btnCreateAdEnabled: true,
        jobId: null
    }

    const {state, setState} = useContext(TikTokAdsContext)
    const [localState, setLocalState] = useState(initialLocalState)
    useEffect(() => {
        getFlows(state.selectedApp).then(response => {
            setState(prevState => {
                return {
                    ...prevState, ...{
                        flowList: response.data,
                        selectedFlow: '',
                    }
                }
            })
        })
        if (state.selectedAdType === 'SINGLE_VIDEO') {
            getTexts(state.selectedApp).then(response => {
                setState(prevState => {
                    return {
                        ...prevState, ...{
                            txtList: response.data,
                            selectedTextId: 1,
                        }
                    }
                })
            })
        }
        // eslint-disable-next-line
    }, [state.selectedApp, state.selectedAdType])

    const flowList = state.flowList.map(item => {
        return (
            <MenuItem key={item.name} value={item.name}>{item.name}</MenuItem>
        )
    })

    const genderList = [
        "GENDER_UNLIMITED", "GENDER_FEMALE", "GENDER_MALE"
    ].map(item => {
        return (
            <MenuItem key={item} value={item}>{item}</MenuItem>
        )
    })

    const handleAdCreate = () => {
        const params = {
            creatives: state.selectedCreatives,
            app: state.selectedApp,
            ad_type: state.selectedAdType,
            ad_group_id: state.adGroupNumberId,
            flow_name: state.selectedFlow,
            gender: state.selectedAdGroupGender,
            budget: state.budget,
            campaigns: state.selectedCampaigns,
            ad_account_id: state.selectedAdAccount,
            text_id: state.selectedTextId,
        }
        setLocalState(prevState => {
            return {...prevState, ...{loading: true}}
        })

        createTikTokAd(params).then(response => {
            const jobId = response.data.job_id
            setTimeout(function check() {
                checkJobStatus(jobId).then(response => {
                    switch (response.data.status) {
                        case 'queued':
                        case 'scheduled':
                        case 'started':
                            setTimeout(check, 10000)
                            break
                        case 'finished':
                            setLocalState(prevState => {
                                return {
                                    ...prevState, ...{
                                        loading: false, response: response.data.result, isComplete: true,
                                    }
                                }
                            })
                            break
                        case 'failed':
                            setLocalState(prevState => {
                                return {...prevState, ...{isComplete: true, loading: false}}
                            })
                            alert('An error occurred')
                            break
                        default:
                            console.log(response.data)
                    }
                })
            }, 10000)
        }).catch(e => {
            console.log(e)
        })

    }

    return localState.isComplete === false ? (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Choose Ad Settings
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <FormControl sx={{m: 1, width: 500}}>
                        <TextField
                            label="Ad Group Number"
                            defaultValue=""
                            helperText=""
                            onChange={(e) => {
                                setState(prevState => {
                                    return {...prevState, ...{adGroupNumberId: e.target.value}}
                                })
                            }}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormControl sx={{m: 1, width: 500}}>
                        <InputLabel variant="outlined" id="flow-label-id">Flow</InputLabel>
                        <Select
                            labelId="flow-label-id"
                            value={state.selectedFlow}
                            label="Flow"
                            onChange={(event) => {
                                setState(prevState => {
                                    return {...prevState, ...{selectedFlow: event.target.value}}
                                })
                            }}
                            fullWidth
                        >
                            {flowList}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl sx={{m: 1, width: 500}}>
                        <InputLabel htmlFor="outlined-adornment-amount">Budget</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-amount"
                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            label="Budget"
                            value={state.budget}
                            onChange={(event) => {
                                setState(prevState => {
                                    return {...prevState, ...{budget: event.target.value}}
                                })
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl sx={{m: 1, width: 500}}>
                        <InputLabel variant="outlined" id="genderId-label">Gender</InputLabel>
                        <Select
                            labelId="genderId-label"
                            value={state.selectedAdGroupGender}
                            label="Gender"
                            onChange={(event) => {
                                setState(prevState => {
                                    return {...prevState, ...{selectedAdGroupGender: event.target.value}}
                                })
                            }}
                            fullWidth
                        >
                            {genderList}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                    {state.selectedAdType === 'SINGLE_VIDEO' ? (
                        <FormControl sx={{m: 1, width: 500}}>
                            <InputLabel variant="outlined" id="adtxt-label-id">Ad Text</InputLabel>
                            <Select
                                labelId="adtxt-label-id"
                                value={state.selectedTextId}
                                label="Ad Text"
                                onChange={(event) => {
                                    setState(prevState => {
                                        return {...prevState, ...{selectedTextId: event.target.value}}
                                    })
                                }}
                                fullWidth
                            >
                                {state.txtList.map(item => {return (
                                    <MenuItem key={item.id} value={item.id}>{item.text}</MenuItem>
                                )
                                })}
                            </Select>
                        </FormControl>
                    ) : (
                        <div></div>
                    )}
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormControl sx={{m: 1, width: 500}}>
                        {!localState.loading && localState.btnCreateAdEnabled ? (
                            <Button
                                variant="contained"
                                onClick={handleAdCreate}
                                sx={{mt: 3, ml: 1}}
                            >Create Ad</Button>
                        ) : (
                            <div>
                                <Backdrop
                                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                                    open={localState.loading}
                                >
                                    <CircularProgress color="inherit"/>
                                </Backdrop>
                            </div>
                        )
                        }
                    </FormControl>
                </Grid>
            </Grid>
        </React.Fragment>
    ) : (
        <React.Fragment>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Account Ad ID</TableCell>
                            <TableCell align="right">AdGroup</TableCell>
                            <TableCell align="right">Creative</TableCell>
                            <TableCell align="right">Result</TableCell>
                            <TableCell align="right">Ad ID</TableCell>
                            <TableCell align="right">Message</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {localState.response.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell component="th" scope="row">
                                    {row.advertiser_id}
                                </TableCell>
                                <TableCell align="right">{row.adgroup_name}</TableCell>
                                <TableCell align="right">{row.creative_name}</TableCell>
                                <TableCell align="right">{row.is_successful === true ? ("✅") : ("❌")}</TableCell>
                                <TableCell align="right">{row.ad_id}</TableCell>
                                <TableCell align="right">{row.message}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    )
}
