import * as React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Toolbar from '@mui/material/Toolbar'
import Paper from '@mui/material/Paper'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import {createTheme, ThemeProvider} from '@mui/material/styles'
import AppForm from '../components/AppForm'
import CreativeForm from '../components/CreativeForm'
import AdAccountsForm from '../components/AdAccountsForm'
import {AppContext} from "../app-context"
import {CampaignForm} from "../components/CampaignForm"
import {AdSetForm} from "../components/AdSetForm"
import AdForm from '../components/AdForm'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const steps = ['App & Platform', 'Ad Accounts', 'Campaigns', 'Ad Sets', 'Creatives', 'Ad'];
const theme = createTheme();

const initialState = {
    activeStep: 0,
    selectedAdAccounts: [],
    selectedCreatives: [],
    selectedCampaigns: [],
    selectedAdsets: [],
    selectedApp: "YG",
    selectedPlatform: "WEB",
    selectedMarketingTeam: "UA",
    selectedAdType: "dlo",
    creativeList: [],
    adTypeList: [],
    adAccountsList: [],
    campaignList: [],
    adSetList: [],
    isLoading: false,
}

function AppContextProvider({children}) {
    const [state, setState] = React.useState(initialState)
    return (
        <AppContext.Provider value={{state, setState}}>
            {children}
        </AppContext.Provider>
    )
}

export default function App() {
    const [activeStep, setActiveStep] = React.useState(0)

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return <AppForm/>
            case 1:
                return <AdAccountsForm/>
            case 2:
                return <CampaignForm/>
            case 3:
                return <AdSetForm/>
            case 4:
                return <CreativeForm/>
            case 5:
                return <AdForm/>
            default:
                throw Object("Unknown step")
        }
    }

    const handleNext = () => {
        setActiveStep(activeStep + 1)
    }

    const restart = () => {
        setActiveStep(0)
    }

    const handleBack = () => {
        setActiveStep(activeStep - 1)
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <AppBar
                position="absolute"
                color="default"
                elevation={0}
                sx={{
                    position: 'relative',
                    borderBottom: (t) => `1px solid ${t.palette.divider}`,
                }}
            >
                <Toolbar>
                    <Typography variant="h6" color="inherit" noWrap>
                        WellTech: Facebook Ads Automation
                    </Typography>
                </Toolbar>
            </AppBar>
            <ToastContainer />
            <Container component="main" maxWidth="lg" sx={{mb: 4}}>
                <Paper variant="outlined" sx={{my: {xs: 3, md: 6}, p: {xs: 2, md: 3}}}>
                    <Typography component="h1" variant="h4" align="center">
                        Facebook Ads Automation
                    </Typography>
                    <Stepper activeStep={activeStep} sx={{pt: 3, pb: 5}}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <React.Fragment>
                        {activeStep === steps.length ? (
                            <React.Fragment>
                                <Typography variant="h5" gutterBottom>
                                    Congratulations! 🎉
                                </Typography>
                                <Typography variant="subtitle1">
                                    The request has been sent, please wait until all ads are ready 🙏
                                </Typography>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <AppContextProvider>
                                    {getStepContent(activeStep)}
                                </AppContextProvider>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                    {activeStep !== 0 && (
                                        <Button onClick={handleBack} sx={{mt: 3, ml: 1}}>
                                            Back
                                        </Button>
                                    )}

                                    {activeStep < steps.length - 1 ? (
                                        <Button
                                            variant="contained"
                                            onClick={handleNext}
                                            sx={{mt: 3, ml: 1}}
                                        >
                                            Next
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="contained"
                                            onClick={restart}
                                            sx={{mt: 3, ml: 1}}
                                        >
                                            Restart
                                        </Button>
                                    )}
                                </Box>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                </Paper>
            </Container>
        </ThemeProvider>
    );
}
