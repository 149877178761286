import {Fragment, useEffect, useState} from "react"
import {getMe} from "../api"


export default function AuthProvider({children}) {
    const [isAuthenticated, setAuth] = useState(false)

    useEffect(() => {
        getMe().then(response => {
            setAuth(response.data.auth)
            console.log(response)
        })
    })

    return isAuthenticated === true ? (
        <Fragment>
            {children}
        </Fragment>
    ) : (
        <p>
            You are not authorized. Please <a href={process.env.REACT_APP_LOGIN_REDIRECT_URL}>log in</a>
        </p>
    )
}
