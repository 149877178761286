import * as React from 'react'
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";


export default function CreatedPostTable({rows}) {
    return (
        <React.Fragment>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Page Title</TableCell>
                            <TableCell align="right">Page ID</TableCell>
                            <TableCell align="right">Message</TableCell>
                            <TableCell align="right">Post ID</TableCell>
                            <TableCell align="right">Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell align="right">{row.name}</TableCell>
                                <TableCell align="right">{row.page_id}</TableCell>
                                <TableCell align="right">{row.message}</TableCell>
                                <TableCell align="right">{row.post_id}</TableCell>
                                <TableCell align="right">{row.post_id === '' ? ("❌") : ("✅")}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    )
}