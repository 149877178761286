import * as React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import {DataGrid} from '@mui/x-data-grid';
import {useEffect, useContext} from "react";
import {TikTokAdsContext} from "../../app-context";
import {getCampaigns} from '../../api/tiktok'


export function CampaignForm() {
    const {state, setState} = useContext(TikTokAdsContext)

    useEffect(() => {
        setState(prevState => {
            return {...prevState, ...{campaignList: [], isLoading: true, selectedCampaigns: []}}
        })

        const promises = [state.selectedAdAccount].map(accountId => {
            return getCampaigns(accountId)
        })

        Promise.all(promises).then(results => {
            const rows = results.flatMap(response => {
                return response.data
            })
            setState(prevState => {
                return {...prevState, ...{campaignList: rows, isLoading: false}}
            })
        })
        // eslint-disable-next-line
    }, [state.selectedAdAccount])

    const columns = [
        {field: 'id', headerName: 'Campaign ID', width: 160},
        {field: 'campaign_name', headerName: 'Campaign Name', width: 750},
        {field: 'operation_status', headerName: 'Status', width: 100},
    ]

    const handleSelectionMode = (rows, details) => {
        console.log('selecting campaigns')
        setState(prevState => {
            return {...prevState, ...{selectedCampaigns: rows}}
        })
    }

    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Choose Campaigns
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <div style={{height: 500, width: '100%'}}>
                        <DataGrid
                            rows={state.campaignList}
                            columns={columns}
                            checkboxSelection
                            loading={state.isLoading}
                            onSelectionModelChange={handleSelectionMode}
                        />
                    </div>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
