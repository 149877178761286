import {api} from "../api"


export function getAccounts(app) {
    return api.get(`/api/v1/tiktok_ads_manager/accounts/?app=${app}&platform=WEB`)
}

export function getCampaigns(accountId) {
    return api.get(`/api/v1/tiktok_ads_manager/get_campaigns/${accountId}/`)
}

export function getFlows(app) {
    return api.get(`/api/v1/tiktok_ads_manager/get_flows/?app=${app}`)
}

export function createTikTokAd(params) {
    return api.post(`/api/v1/tiktok_ads_manager/create_ad/`, params)
}


export function getTexts(app) {
    return api.get(`/api/v1/tiktok_ads_manager/get_texts/?app=${app}`)
}
