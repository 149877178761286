import * as React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Toolbar from '@mui/material/Toolbar'
import Paper from '@mui/material/Paper'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import {createTheme, ThemeProvider} from '@mui/material/styles'
import AppForm from '../components/TikTokAds/AppForm'
import {TikTokAdsContext} from "../app-context"
import {CampaignForm} from "../components/TikTokAds/CampaignForm"
import CreativeForm from "../components/TikTokAds/CreativeForm"
import AdForm from "../components/TikTokAds/AdForm"
import {ToastContainer} from "react-toastify"
import 'react-toastify/dist/ReactToastify.css'


const steps = ['Ad Settings', 'Choose Campaign', 'Choose Creative', 'Create Ad'];
const theme = createTheme();

const initialState = {
    activeStep: 0,
    selectedAdAccount: '',
    selectedFlow: '',
    selectedCreatives: [],
    selectedCampaigns: [],
    selectedApp: "WF",
    selectedAdType: "SMART_AD",
    creativeList: [],
    adTypeList: [],
    adAccountsList: [],
    flowList: [],
    campaignList: [],
    isLoading: false,
    budget: 600,
    selectedAdGroupGender: "GENDER_UNLIMITED",
    adGroupNumberId: '',
    selectedTextId: 1,
    txtList: []
}

function AppContextProvider({children}) {
    const [state, setState] = React.useState(initialState)
    return (
        <TikTokAdsContext.Provider value={{state, setState}}>
            {children}
        </TikTokAdsContext.Provider>
    )
}

export default function TikTokApp() {
    const [activeStep, setActiveStep] = React.useState(0)

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return <AppForm/>
            case 1:
                return <CampaignForm/>
            case 2:
                return <CreativeForm/>
            case 3:
                return <AdForm/>
            default:
                throw Object("Unknown step")
        }
    }

    const handleNext = () => {
        setActiveStep(activeStep + 1)
    }

    const restart = () => {
        setActiveStep(0)
    }

    const handleBack = () => {
        setActiveStep(activeStep - 1)
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <AppBar
                position="absolute"
                color="default"
                elevation={0}
                sx={{
                    position: 'relative',
                    borderBottom: (t) => `1px solid ${t.palette.divider}`,
                }}
            >
                <Toolbar>
                    <Typography variant="h6" color="inherit" noWrap>
                        WellTech: TikTok Ads Automation
                    </Typography>
                </Toolbar>
            </AppBar>
            <ToastContainer />
            <Container component="main" maxWidth="lg" sx={{mb: 4}}>
                <Paper variant="outlined" sx={{my: {xs: 3, md: 6}, p: {xs: 2, md: 3}}}>
                    <Typography component="h1" variant="h4" align="center">
                        TikTok Ads Automation
                    </Typography>
                    <Stepper activeStep={activeStep} sx={{pt: 3, pb: 5}}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <React.Fragment>
                        {activeStep === steps.length ? (
                            <React.Fragment>
                                <Typography variant="h5" gutterBottom>
                                    Congratulations! 🎉
                                </Typography>
                                <Typography variant="subtitle1">
                                    The request has been sent, please wait until all ads are ready 🙏
                                </Typography>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <AppContextProvider>
                                    {getStepContent(activeStep)}
                                </AppContextProvider>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                    {activeStep !== 0 && (
                                        <Button onClick={handleBack} sx={{mt: 3, ml: 1}}>
                                            Back
                                        </Button>
                                    )}

                                    {activeStep < steps.length - 1 ? (
                                        <Button
                                            variant="contained"
                                            onClick={handleNext}
                                            sx={{mt: 3, ml: 1}}
                                        >
                                            Next
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="contained"
                                            onClick={restart}
                                            sx={{mt: 3, ml: 1}}
                                        >
                                            Restart
                                        </Button>
                                    )}
                                </Box>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                </Paper>
            </Container>
        </ThemeProvider>
    );
}
