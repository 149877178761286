export const APP_LIST = [
    {
        name: "Muscle Booster",
        code: "MB",
    },
    {
        name: "YogaGo",
        code: "YG",
    },
    {
        name: "FastEasy",
        code: "FE",
    },
    {
        name: "Femia",
        code: "FM",
    },
    {
        name: "Omo",
        code: "OM",
    },
    {
        name: "WalkFit",
        code:"WF",
    },
    {
        name: "Fottur",
        code:"FT",
    },
]

export const APP_PLATFORMS = [
    {
        name: "Web",
        code: "WEB",
    },
    {
        name: "Mobile",
        code: "MOBILE",
    },
]


export const MARKETING_TEAM = [
    {
        name: "User Acquisition",
        code: "UA",
    },
    {
        name: "Marketing Manager",
        code: "MM",
    }
]


export const TIKTOK_ADS_AD_LIST = [
    {
        "name": "Smart Ad",
        "code": "SMART_AD",
    },
    {
        "name": "Single Video Ad",
        "code": "SINGLE_VIDEO",
    },
]
