import * as React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import {DataGrid} from '@mui/x-data-grid'
import {useEffect, useContext} from "react"
import {AppContext} from "../app-context"
import {getAdsets} from '../api'


export function AdSetForm() {
    const {state, setState} = useContext(AppContext)

    useEffect(() => {
        setState(prevState => {
            return {...prevState, ...{adSetList: [], isLoading: true, selectedAdsets: []}}
        })

        const promises = state.selectedCampaigns.map(campaignId => {
            return getAdsets(campaignId, state.selectedMarketingTeam, state.selectedPlatform, state.selectedAdType)
        })

        Promise.all(promises).then(results => {
            const rows = results.flatMap(response => {
                console.log(response.data)
                return response.data
            })
            setState(prevState => {
                return {...prevState, ...{adSetList: rows, isLoading: false}}
            })
        })
        // eslint-disable-next-line
    }, [state.selectedCampaigns, state.selectedMarketingTeam])

    const columns = [
        // {field: 'id', headerName: 'Adset ID', width: 100},
        {field: 'name', headerName: 'Adset Name', width: 850},
        {field: 'ads', headerName: '№ Ads', width: 100},
        {field: 'status', headerName: 'Status', width: 80},
        // {field: 'account_id', headerName: 'Account ID', width: 150},
    ]

    const handleSelectionMode = (rows, details) => {
        const selectedRows = state.adSetList.filter(item => rows.includes(item.id))
        setState(prevState => {
            return {...prevState, ...{selectedAdsets: selectedRows}}
        })
    }

    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Choose Ad Sets
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <div style={{height: 500, width: '100%'}}>
                        <DataGrid
                            rows={state.adSetList}
                            columns={columns}
                            checkboxSelection
                            loading={state.isLoading}
                            onSelectionModelChange={handleSelectionMode}
                            isRowSelectable={(params) => {
                                return params.row.disabled === false
                            }}
                        />
                    </div>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
