import * as React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import {AppContext} from "../app-context";
import {useContext, useEffect} from "react";
import {APP_LIST, APP_PLATFORMS, MARKETING_TEAM} from "../constants"
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import {getAdTypes} from '../api'


export default function AppForm() {
    const {state, setState} = useContext(AppContext)

    useEffect(() => {
        getAdTypes(state.selectedPlatform).then(response => {
            setState(prevState => {
                return {...prevState, ...{adTypeList: response.data, selectedAdType: response.data[0].code}}
            })
        })  // eslint-disable-next-line
    }, [state.selectedPlatform])

    const handleSelectAppChange = (event) => {
        setState(prevState => {
            return {...prevState, ...{selectedApp: event.target.value}}
        })
    }

    const handleSelectPlatformChange = (event) => {
        setState(prevState => {
            return {...prevState, ...{selectedPlatform: event.target.value}}
        })
    }

    const appList = APP_LIST.map(item => {
        return (
            <MenuItem key={item.code} value={item.code}>{item.name}</MenuItem>
        )
    })

    const platformList = APP_PLATFORMS.map(item => {
        return (
            <MenuItem key={item.code} value={item.code}>{item.name}</MenuItem>
        )
    })

    const marketingTeamList = MARKETING_TEAM.map(item => {
        return (
            <MenuItem key={item.code} value={item.code}>{item.name}</MenuItem>
        )
    })

    const adTypeList = state.adTypeList.map(item => {
        return (
            <MenuItem key={item.code} value={item.code}>{item.name}</MenuItem>
        )
    })

    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Choose App & Platform
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <FormControl sx={{m: 1, width: 500}}>
                        <InputLabel variant="outlined" id="application-label-id">Application</InputLabel>
                        <Select
                            labelId="application-label-id"
                            id="app-id"
                            value={state.selectedApp}
                            label="Application"
                            onChange={handleSelectAppChange}
                            fullWidth
                        >
                            {appList}
                        </Select>
                    </FormControl>

                    <FormControl sx={{m: 1, width: 500}}>
                        <InputLabel variant="outlined" id="marketing-team-label-id">Marketing Team</InputLabel>
                        <Select
                            labelId="marketing-team-label-id"
                            id="marketing-team-id"
                            value={state.selectedMarketingTeam}
                            label="Marketing Team"
                            onChange={(event) => {
                                setState(prevState => {
                                    return {...prevState, ...{selectedMarketingTeam: event.target.value}}
                                })
                            }}
                            fullWidth
                        >
                            {marketingTeamList}
                        </Select>
                    </FormControl>

                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl sx={{m: 1, width: 500}}>
                        <InputLabel variant="outlined" id="platform-label-id">Platform</InputLabel>
                        <Select
                            labelId="platform-label-id"
                            value={state.selectedPlatform}
                            label="Platform"
                            onChange={handleSelectPlatformChange}
                            fullWidth
                        >
                            {platformList}
                        </Select>
                    </FormControl>

                    <FormControl sx={{m: 1, width: 500}}>
                        <InputLabel variant="outlined" id="ad-type-label-id">Ad Type</InputLabel>
                        <Select
                            labelId="ad-type-label-id"
                            value={state.selectedAdType}
                            label="Ad Type"
                            onChange={(event) => {
                                setState(prevState => {
                                    return {...prevState, ...{selectedAdType: event.target.value}}
                                })
                            }}
                            fullWidth
                        >
                            {adTypeList}
                        </Select>
                    </FormControl>

                </Grid>
            </Grid>
        </React.Fragment>
    );
}
