import axios from 'axios'
import showErrorToast from './components/ErrorToast';


function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

const API_BASE_URL = process.env["REACT_APP_API_BASE_URL"]
export const api = axios.create({
    withCredentials: true,
    baseURL: API_BASE_URL,
    headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": getCookie("csrftoken"),
    }
})

api.interceptors.response.use(
    response => response,
    error => {
        showErrorToast(error.response.data.error ? error.response.data.error : error.message)
    }
)

export function getAdAccounts(app, platform) {
    return api.get(`/api/v1/facebook_manager/get_ad_accounts/?app=${app}&platform=${platform}`)
}

export function getCampaigns(facebookAccountId) {
    return api.get(`/api/v1/facebook_manager/get_campaigns/${facebookAccountId}`)
}

export function getAdsets(campaignId, team, platform, ad_type) {
    return api.get(
        `/api/v1/facebook_manager/get_adsets/${campaignId}?team=${team}&platform=${platform}&ad_type=${ad_type}`
    )
}

export function getCreatives(app, ad_type, network = 'Facebook') {
    return api.get(`/api/v1/creative_manager/get_creatives?app=${app}&ad_type=${ad_type}&network=${network}`)
}

export function getCreativesByCreativeId(app, creativeId, ad_type, network = 'Facebook') {
    return api.get(`/api/v1/creative_manager/get_creatives?app=${app}&query=${creativeId}&ad_type=${ad_type}&network=${network}`)
}

export function createFacebookAd(params) {
    return api.post(`/api/v1/facebook_manager/create_ad`, params)
}

export function getMe() {
    return api.get(`/api/v1/user/me`)
}

export function getCallToActions(platform) {
    return api.get(`/api/v1/facebook_manager/get_call_to_actions/?platform=${platform}`)
}

export function getAdTypes(platform) {
    return api.get(`/api/v1/facebook_manager/get_ad_types/?platform=${platform}`)
}

export function getConversionDomains(app) {
    return api.get(`/api/v1/facebook_manager/get_domains/?app=${app}`)
}

export function getSmmCreatives() {
    return api.get(`/api/v1/creative_manager/smm/creatives`)
}

export function getSmmPages(app) {
    return api.get(`/api/v1/smm_poster/pages?app=${app}`)
}

export function getSmmPosts(app) {
    return api.get(`/api/v1/smm_poster/templates?app=${app}`)
}

export function createFacebookPost(params) {
    return api.post(`/api/v1/smm_poster/create_post`, params)
}

export function getAdSettings(params) {
    return api.post(`/api/v1/facebook_manager/get_ad_settings/`, params)
}

export function checkJobStatus(jobId) {
    return api.get(`/api/v1/job_status?job_id=${jobId}`)
}

export function schedulePost(params) {
    return api.post(`/api/v1/smm_poster/schedule_post`, params)
}
