import * as React from 'react';
import App from './containers/App'
import SmmApp from './containers/SmmPoster'
import TikTokApp from './containers/TikTokAds'
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom"
import AuthProvider from "./containers/AuthProvider";
import { createRoot } from 'react-dom/client'


const container = document.getElementById('root')
const root = createRoot(container)

root.render(
    <BrowserRouter>
        <Routes>
            <Route path="/" element={
                <AuthProvider>
                    <App/>
                </AuthProvider>
            }/>
            <Route path="smm-poster" element={
                <AuthProvider>
                    <SmmApp/>
                </AuthProvider>
            }/>
            <Route path="tiktok" element={
                <AuthProvider>
                    <TikTokApp/>
                </AuthProvider>
            }/>
        </Routes>
    </BrowserRouter>
)
