import * as React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import {useEffect, useContext} from "react"
import {AppContext} from "../app-context"
import {getAdAccounts} from '../api'


export default function AdAccountsForm() {
    const {state, setState} = useContext(AppContext)

    useEffect(() => {
        getAdAccounts(state.selectedApp, state.selectedPlatform).then(response => {
            setState(prevState => {
                return {...prevState, ...{
                    adAccountsList: response.data.filter(item => item.is_active === true), selectedAdAccounts: []}
                }
            })
        })  // eslint-disable-next-line
    }, [state.selectedApp, state.selectedPlatform])

    const adAccountList = state.adAccountsList.map(item => {
        return (
            <MenuItem key={item.id} value={item.ad_account_id}>{item.name}</MenuItem>
        )
    })

    const handleSelectAdAccountChange = (event, child) => {
        const adAccountName = child.props.value

        setState(prevState => {
            let selectedAccounts = [...prevState.selectedAdAccounts]
            const index = selectedAccounts.indexOf(adAccountName)
            console.log(adAccountName)
            if (index > -1) {
                console.log('already in the list')
                selectedAccounts.splice(index, 1)
            } else {
                console.log('new, add to the list')
                selectedAccounts.push(adAccountName)
            }

            return {...prevState, ...{selectedAdAccounts: selectedAccounts}}
        })
    }

    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Choose Facebook Ad Accounts
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <Select
                        value={state.selectedAdAccounts}
                        multiple
                        label="Ad Accounts"
                        onChange={(event, child) => handleSelectAdAccountChange(event, child)}
                        fullWidth
                    >
                        {adAccountList}
                    </Select>
                </Grid>

            </Grid>
        </React.Fragment>
    );
}
