import * as React from 'react'
import {useContext, useEffect, useState} from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import {AppContext} from "../app-context"
import Button from '@mui/material/Button'
import {
    createFacebookAd,
    getAdSettings,
    getCallToActions,
    getConversionDomains,
    checkJobStatus,
} from '../api'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import TextField from '@mui/material/TextField'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Switch from '@mui/material/Switch'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import AdSettingsSelector from './AdSettingsSelector'


export default function AdForm() {
    const initialLocalState = {
        selectedCallToAction: "",
        optionalAdLabelSuffix: "",
        loading: false,
        isComplete: false,
        response: [],
        hotjar: false,
        callToActionList: [],
        conversionDomainList: [],
        selectedConversionDomain: "",
        adSettings: {},
        selectedAdSettings: {},
        btnCreateAdEnabled: false,
        jobId: null,
    }

    const {state} = useContext(AppContext)
    const [localState, setLocalState] = useState(initialLocalState)

    useEffect(() => {
        getCallToActions(state.selectedPlatform).then(response => {
            setLocalState(prevState => {
                return {...prevState, ...{callToActionList: response.data, selectedCallToAction: response.data[0].code}}
            })
        })

        getConversionDomains(state.selectedApp).then(response => {
            setLocalState(prevState => {
                return {
                    ...prevState, ...{
                        conversionDomainList: response.data,
                        selectedConversionDomain: response.data[0],
                    }
                }
            })
        })

        const adSettingsParams = {
            app: state.selectedApp,
            platform: state.selectedPlatform,
            marketing_team: state.selectedMarketingTeam,
            ad_sets: state.selectedAdsets,
        }

        getAdSettings(adSettingsParams).then(response => {
            let selectedSettings = {}
            Object.entries(response.data).forEach(function (item) {
                const [flow, params] = item
                const defaultPage = params.pages.filter(item => item.is_default === true).pop()
                const defaultText = params.texts.filter(item => item.is_default === true).pop()
                selectedSettings[flow] = {
                    page_id: defaultPage.id,
                    text_id: defaultText.id,
                }
            })

            setLocalState(prevState => {
                return {
                    ...prevState, ...{
                        adSettings: response.data,
                        selectedAdSettings: selectedSettings,
                        btnCreateAdEnabled: true,
                    }
                }
            })
        }).catch(e => {
            console.log(e)
        })

    }, [state.selectedPlatform, state.selectedApp, state.selectedMarketingTeam, state.selectedAdsets])

    const displayActions = localState.callToActionList.map(item => {
        return (
            <MenuItem key={item.code} value={item.code}>{item.name}</MenuItem>
        )
    })

    const displayConversionDomains = localState.conversionDomainList.map(item => {
        return (
            <MenuItem key={item} value={item}>{item}</MenuItem>
        )
    })

    const displayAdSettingsSelectors = () => {
        let components = []

        for (const item in localState.adSettings) {
            components.push(
                (
                    <AdSettingsSelector
                        pages={localState.adSettings[item].pages}
                        texts={localState.adSettings[item].texts}
                        valuePage={localState.selectedAdSettings[item].page_id}
                        valueText={localState.selectedAdSettings[item].text_id}
                        title={item}
                        key={`selector-${item}`}
                        onChangePage={(e) => {
                            setLocalState(prevState => {
                                let adSettings = {...prevState.selectedAdSettings}
                                adSettings[item].page_id = e.target.value
                                return {
                                    ...prevState, ...{
                                        selectedAdSettings: adSettings,
                                    }
                                }
                            })
                        }}
                        onChangeText={(e) => {
                            setLocalState(prevState => {
                                let adSettings = {...prevState.selectedAdSettings}
                                adSettings[item].text_id = e.target.value
                                return {
                                    ...prevState, ...{
                                        selectedAdSettings: adSettings,
                                    }
                                }
                            })
                        }}
                        id={item}
                    />
                )
            )
        }
        return components
    }

    const handleAdCreate = () => {
        const params = {
            call_to_action: localState.selectedCallToAction,
            optional_ad_label: localState.optionalAdLabelSuffix,
            creatives: state.selectedCreatives,
            ad_sets: state.selectedAdsets,
            app: state.selectedApp,
            platform: state.selectedPlatform,
            hotjar: localState.hotjar,
            team: state.selectedMarketingTeam,
            ad_type: state.selectedAdType,
            conversion_domain: localState.selectedConversionDomain,
            ad_settings: localState.selectedAdSettings,
        }
        setLocalState(prevState => {
            return {...prevState, ...{loading: true}}
        })

        createFacebookAd(params).then(response => {
            const jobId = response.data.job_id
            setTimeout(function check() {
                checkJobStatus(jobId).then(response => {
                    switch (response.data.status) {
                        case 'queued':
                        case 'scheduled':
                        case 'started':
                            setTimeout(check, 10000)
                            break
                        case 'finished':
                            setLocalState(prevState => {
                                return {
                                    ...prevState, ...{
                                        loading: false, response: response.data.result, isComplete: true,
                                    }
                                }
                            })
                            break
                        case 'failed':
                            setLocalState(prevState => {
                                return {...prevState, ...{isComplete: true, loading: false}}
                            })
                            alert('An error occurred')
                            break
                        default:
                            console.log(response.data)
                    }
                })
            }, 10000)
        }).catch(e => {
            console.log(e)
        })
    }

    return localState.isComplete === false ? (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Hey! We need to make sure that everything is OK to proceed to ad creation 🎉
            </Typography>

            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <FormControl sx={{m: 1, width: 500}}>
                        <InputLabel variant="outlined" id="call-to-action-label-id">Call to action</InputLabel>
                        <Select
                            value={localState.selectedCallToAction}
                            labelId="call-to-action-label-id"
                            label="Call to action"
                            fullWidth
                            onChange={(e) => {
                                setLocalState(prevState => {
                                    return {...prevState, ...{selectedCallToAction: e.target.value}}
                                })
                            }}
                        >
                            {displayActions}
                        </Select>
                    </FormControl>

                    {state.selectedPlatform === 'WEB' ? (
                        <FormControl sx={{m: 1, width: 500}}>
                            <InputLabel variant="outlined" id="conversion-domain-label-id">Conversion Domain</InputLabel>
                            <Select
                                value={localState.selectedConversionDomain}
                                label="Conversion Domain"
                                labelId="conversion-domain-label-id"
                                fullWidth
                                onChange={(e) => {
                                    setLocalState(prevState => {
                                        return {...prevState, ...{selectedConversionDomain: e.target.value}}
                                    })
                                }
                                }>
                                {displayConversionDomains}
                            </Select>
                        </FormControl>
                    ) : ("")}

                    <FormControl sx={{m: 1, width: 500}}>
                        <TextField
                            label="Ad label suffix (Optional)"
                            defaultValue=""
                            helperText="Optional text at the end of ad label"
                            onChange={(e) => {
                                setLocalState(prevState => {
                                    return {...prevState, ...{optionalAdLabelSuffix: e.target.value}}
                                })
                            }}
                        />

                    </FormControl>

                    {displayAdSettingsSelectors()}

                </Grid>


                <Grid item xs={12} sm={6}>
                    {state.selectedPlatform === 'WEB' ? (
                        <FormGroup sx={{m: 1, width: 100}}>
                            <FormControlLabel control={<Switch color="primary" onChange={e => {
                                setLocalState(prevState => {
                                    return {...prevState, ...{hotjar: e.target.checked}}
                                })
                            }}/>} label="Hotjar"/>
                        </FormGroup>
                    ) : ("")}

                    {!localState.loading && localState.btnCreateAdEnabled ? (
                        <Button
                            variant="contained"
                            onClick={handleAdCreate}
                            sx={{mt: 3, ml: 1}}
                        >Create Ad</Button>
                    ) : (
                        <div>
                            <Backdrop
                                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                                open={localState.loading}
                            >
                                <CircularProgress color="inherit"/>
                            </Backdrop>
                        </div>
                    )
                    }
                </Grid>
            </Grid>
        </React.Fragment>

    ) : (
        <React.Fragment>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Ad Account</TableCell>
                            <TableCell align="right">Adset</TableCell>
                            <TableCell align="right">Creative</TableCell>
                            <TableCell align="right">Result</TableCell>
                            <TableCell align="right">Ad ID</TableCell>
                            <TableCell align="right">Message</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {localState.response.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell component="th" scope="row">
                                    {row.account_id}
                                </TableCell>
                                <TableCell align="right">{row.ad_set}</TableCell>
                                <TableCell align="right">{row.creative}</TableCell>
                                <TableCell align="right">{row.success === true ? ("✅") : ("❌")}</TableCell>
                                <TableCell align="right">{row.ad_id}</TableCell>
                                <TableCell align="right">{row.output}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    )
}
