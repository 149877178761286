import * as React from 'react'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from "@mui/material/InputLabel";
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import FormGroup from '@mui/material/FormGroup'


export default function AdSettingsSelector(props) {
    const pageItems = props.pages.map(item => {
        return (
            <MenuItem key={item.id} value={item.id}>
                {item.name}
            </MenuItem>
        )
    })

    const textItems = props.texts.map(item => {
        return (
            <MenuItem key={item.id} value={item.id}>
                {item.name}
            </MenuItem>
        )
    })

    return (
        <FormGroup>
            <FormLabel id="demo-radio-buttons-group-label">{props.title}</FormLabel>
            <FormControl sx={{m: 1, width: 500}}>
                <InputLabel variant="outlined" id="facebook-page-label-id">
                    Facebook Page
                </InputLabel>
                <Select
                    value={props.valuePage}
                    labelId="facebook-page-label-id"
                    label="Facebook Page"
                    key={props.title}
                    fullWidth
                    onChange={props.onChangePage}
                >
                    {pageItems}
                </Select>
            </FormControl>

            <FormControl sx={{m: 1, width: 500}}>
                <InputLabel variant="outlined" id="text-label-id">
                    Text
                </InputLabel>
                <Select
                    value={props.valueText}
                    labelId="text-label-id"
                    label="Text"
                    key={props.title}
                    fullWidth
                    onChange={props.onChangeText}
                >
                    {textItems}
                </Select>
            </FormControl>
        </FormGroup>
    )
}
